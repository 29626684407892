
import { defineComponent, ref } from 'vue';
import updateBillingList from '@/components/view/common/dialog/update-billing-list';
import { update } from '@/data';
import { userForSuperApi } from '@/api';
import { ListContent } from '@/components/common/list';

export default defineComponent({
    components: {
        updateBillingList
    },
    setup() {
        const detail = JSON.parse(localStorage.getItem('installerInfo')!);
        const showUpdateList = ref(false);
        const initData = ref<Array<ListContent>>([]);

        // 获取所有刷新单住户
        const getUpdateList = () => {
            userForSuperApi.updateBillingList({
                ID: detail.ID,
                Step: update.stepForGetList,
                Type: update.updateForSingle
            }, [(res: {
                data: {
                    detail: Array<ListContent>;
                };
            }) => {
                initData.value = res.data.detail;
                showUpdateList.value = true;
            }, false]);
        };
        // 确认刷新计费模型
        const confirmUpdate = () => {
            userForSuperApi.updateBillingList({
                ID: detail.ID,
                Step: update.stepForUpdate,
                Type: update.updateForSingle
            }, () => {
                showUpdateList.value = false;
            });
        };
        return {
            detail,
            showUpdateList,
            getUpdateList,
            initData,
            confirmUpdate
        };
    }
});
